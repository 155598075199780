import React from 'react'
import { graphql } from 'gatsby'
import MainWrapper from '../containers/MainWrapper'
// import SEO from '../components/SEO'
import Header from '../components/Header'
// import Register from '../components/Register'

const Donate = ({ 
  data: { images },
  pageContext: { slug }, 
  location 
}) => {

  const pagesImages = (id) => {
    return images.edges.filter((item) => {
      return parseInt(item.node.name) === id;
    })[0].node.publicURL;
  };
  
  return (
    <React.Fragment>
      {/* <SEO pathname={location.pathname} banner={banners.desktop} /> */}
      <Header slug={slug} location={location} />
      <MainWrapper>
        <section className="odd">
          <div className="container">
            <h1>Faire un don</h1>
          </div>
          <div className="right">
            <img src={pagesImages(7)} alt="" />
          </div>
        </section>

        <form className="register">
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* <h2>Vous</h2> */}
                  <div className="form-group">
                    <button className="checkbox">Par carte bancaire</button>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-3">
                        <button className="checkbox">Par chèque</button>
                      </div>
                      <div className="col-6">
                        Ordre : Association AFC BAB
                        Adresse : xxx, avenue Dubrocq 64 200 Bayonne
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-3">
                        <button className="checkbox">Par virement bancaire</button>
                      </div>
                      <div className="col-6">
                        RIB : FR4976 XXXX YYYY ZZ
                        AFC BAB
                        CICPAYS BASQUE
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-3">
                        <button className="checkbox">Avec Lydia</button>
                      </div>
                      <div className="col-6">
                        Paiement au 06 44 43 12 64
                        (trésorier de l’association)
                      </div>
                    </div>
                  </div>

                  {/* <button className="submit">Je valide</button> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      </MainWrapper>
    </React.Fragment>
  )
}

export default Donate

export const pageQuery = graphql`
  query donatePage {
    images: allFile {
      edges {
        node {
          id
          publicURL
          name
        }
      }
    }
  }
`;